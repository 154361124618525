import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import avatar1 from "../../../assets/img/avatars/avatar.jpg";
import {
  createAnswer,
  getQuestionDetails,
  updateAnswer,
} from "../../../services/api/queries/forum";
import { useParams } from "react-router-dom";
import moment from "moment";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import answerFields from "../../../configs/meta/formFields/answerFields";
import { eventBus } from "../../../services/eventBus";
import useAuth from "../../../hooks/useAuth";

const DiscussionAnswers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [questionDetails, setQuestionDetails] = useState({});
  const params = useParams();

  const { user } = useAuth();

  const tickmarkSvg = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#07969D" />
      <line
        x1="6.68842"
        y1="10.6806"
        x2="12.8991"
        y2="4.46991"
        stroke="white"
        stroke-width="1.5"
      />
      <line
        x1="6.68842"
        y1="11.7413"
        x2="3.68842"
        y2="8.74127"
        stroke="white"
        stroke-width="1.5"
      />
    </svg>
  );

  const performQuery = useCallback(() => {
    setIsLoading(true);
    const res = getQuestionDetails(params)
      .then((info) => {
        if (!info.success) throw info;
        setQuestionDetails(info.data);
        return info;
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    return res;
  }, [params]);
  const getName = (person) => {
    return person.first_name || person.last_name
      ? `${person.first_name} ${person.last_name}`
      : "Anonymous User";
  };

  useEffect(() => {
    performQuery();
  }, [performQuery]);

  useEffect(() => {
    eventBus.subscribe("refresh_question_description", () => performQuery());

    return () => {
      eventBus.unsubscribe("refresh_question_description");
    };
  }, [performQuery]);

  return (
    <React.Fragment>
      <Helmet title="My Courses  Page" />
      <Loader className="h-100" minHeight={false} isLoading={isLoading}>
        <div className=" h-100">
          <div className="row d-flex h-100">
            <div className="col-md-12 h-100">
              <div className="discussion-section p-lg-5 h-100">
                <div className=" discussion-container">
                  <div className="p-3 discussion discussions-answers">
                    <h1 className="discussion__title">
                      {questionDetails?.title}
                    </h1>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-row align-items-center">
                        <img
                          src={questionDetails.asked_by?.image || avatar1}
                          alt="img"
                          width="50"
                          className="rounded-circle"
                        />
                        <div className="d-flex flex-column ms-2">
                          <span className="font-weight-bold">{`${questionDetails.asked_by?.first_name} ${questionDetails.asked_by?.last_name}`}</span>
                          <small className="me-2">
                            {moment(questionDetails?.created_at).format(
                              "MMM DD, YYYY hh:mm a"
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="p-2">
                      <p className="text-justify">
                        {questionDetails?.description}
                      </p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-row muted-color">
                          <span>{questionDetails?.total_answer} Answers</span>
                        </div>
                        <ModalFormOpener
                          key="edit"
                          submitService={createAnswer}
                          fields={answerFields}
                          heading={"Write your Answer!"}
                          openerText="Write Answer"
                          size="xl"
                          doNotReset={true}
                          successMessage="Answer Posted!"
                          openerClassName="btn btn_primary_outlined write_comment_btn mb-0"
                          appendPayload={params}
                          refreshEvent="refresh_question_description"
                        />
                      </div>
                    </div>
                    <hr
                      style={{
                        height: 1,
                        border: "none",
                      }}
                    />
                    <div className="answers">
                      {questionDetails?.answers?.map((answer) => (
                        <div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="d-flex flex-row align-items-center">
                                <div
                                  className={`profile-image-wrapper ${
                                    answer.is_admin_answer ? "admin-photo" : ""
                                  }`}
                                >
                                  <img
                                    src={answer.answered_by?.image || avatar1}
                                    // src={avatar1}
                                    alt="img"
                                    width="50"
                                    className={`rounded-circle cover-photo`}
                                  />
                                </div>

                                {/* <div className="answer-image">
                                  {answer?.is_admin_answer && tickmarkSvg}
                                  <img
                                    // src={answer?.answered_by?.image || avatar1}
                                    src={avatar1}
                                    alt="img"
                                    width="50"
                                    className="rounded-circle"
                                  />
                                </div> */}
                                <div className="d-flex flex-column ms-2">
                                  <span className="font-weight-bold">
                                    {getName(answer?.answered_by)}
                                  </span>
                                  <small className="me-2">
                                    {moment(answer?.created_at).format(
                                      "MMM DD, YYYY hh:mm a"
                                    )}
                                  </small>
                                </div>
                              </div>
                              <div>
                                {answer?.is_admin_answer && (
                                  <div className="btn-see-answers">
                                    Best Answer
                                  </div>
                                )}
                              </div>
                            </div>

                            {user?.username ===
                              answer.answered_by?.username && (
                              <ModalFormOpener
                                key="edit"
                                submitService={updateAnswer}
                                fields={answerFields}
                                heading={"Edit your Answer!"}
                                openerText={
                                  <>
                                    <img
                                      src="/images/Icons/pencil-square.svg"
                                      alt=""
                                      className="me-1"
                                    />
                                    Edit
                                  </>
                                }
                                size="xl"
                                doNotReset={true}
                                defaultValues={answer}
                                successMessage="Answer Posted!"
                                openerClassName="edit_btn"
                                appendPayload={params}
                                refreshEvent="refresh_question_description"
                              />
                            )}
                          </div>
                          <div className="p-2">
                            <p className="text-justify">{answer?.answer}</p>
                            {answer?.image ? (
                              <div className="image-container mx-auto text-center my-2 py-4 ">
                                <img src={answer.image} alt="img" />
                              </div>
                            ) : null}
                          </div>
                          <hr
                            className="mt-0"
                            style={{
                              height: 0.5,
                              border: "none",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </React.Fragment>
  );
};

export default DiscussionAnswers;
