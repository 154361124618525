import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "layouts/Auth";
import DashboardLayout from "layouts/Dashboard";

// Guards
import AuthGuard from "components/guards/AuthGuard";

// Constants
import {
  CATEGORY_PAGE,
  CHARACTERISTIC_PAGE,
  COUPON_PAGE,
  COUPON_USAGE_PAGE,
  COURSE_BUILDER_PAGE,
  COURSE_PAGE,
  ENROLMENT_PAGE,
  INSTRUCTOR_PAGE,
  PROFILE_PAGE,
  NOTICE_PAGE,
  QUESTION_PAGE,
  REGULAR_ENROLMENT_PAGE,
  REVIEW_PAGE,
  SECTION_PAGE,
  SIGN_IN,
  SITE_INFO_PAGE,
  SUB_CATEGORY_PAGE,
  TEMPLATE_PAGE,
  WRITTEN_EVALUATION_PAGE,
  BLOG_CATEGORY,
  BLOG,
  USER_MANAGEMENT,
  FREE_EXAM,
  FREE_EXAM_PARTICIPANTS,
  RESET_PASSWORD,
  UNANSWERED_QUESTIONS,
  CHANGE_PASS,
  CIRCULAR_PAGE,
  JOIN_DISCUSSIONS,
  QUESTION_DETAILS,
  DISCUSSION_PAGE,
  MANAGE_SALES,
  VIEW_TEMPLATE,
  STUDENTS_PAGE,
  SUBSCRIPTION,
  VIEW_INVOICE,
  STUDENTS_REVIEW,
} from "./constants/routes";

// Pages
import Default from "pages/dashboards/Default";
import Blank from "pages/pages/Blank";
import Page404 from "pages/auth/Page404";
import SignIn from "pages/auth/SignIn";
import QuestionTemplate from "./pages/pages/manage-quiz/QuestionTemplate";
import SectionList from "./pages/pages/manage-quiz/SectionList";
import QuestionList from "./pages/pages/manage-quiz/QuestionList";
import Category from "./pages/pages/course-management/Category";
import SubCategory from "./pages/pages/course-management/SubCategory";
import Instructor from "./pages/pages/course-management/Instructor";
import Course from "./pages/pages/course-management/Course";
import CourseBuilder from "./pages/pages/course-management/CourseBuilder";
import Enrolment from "./pages/pages/manage-enrolment/Enrolment";
import Coupon from "./pages/pages/coupon-management/Coupon";
import CouponUsage from "./pages/pages/coupon-management/CouponUsage";
import RegularEnrolment from "./pages/pages/manage-enrolment/RegularEnrolment";
import Profile from "./pages/pages/Profile";
import Notice from "./pages/pages/course-management/Notice";
import Characteristic from "./pages/pages/configuration-management/Characteristic";
import SiteInfo from "./pages/pages/configuration-management/SiteInfo";
import WrittenEvaluation from "./pages/pages/written-evaluation-management/WrittenEvaluation";
import BlogCategory from "./pages/pages/blog/BlogCategory";
import Blog from "./pages/pages/blog/Blog";
import UserManagement from "./pages/pages/user-management/UserManagement";
import FreeExam from "./pages/pages/free-exam/FreeExam";
import FreeExamParticipants from "./pages/pages/free-exam/FreeExamParticipants";
import ResetPasswordPage from "./pages/auth/ResetPassword";
import ChangePasswordPage from "./pages/auth/ChangePassword";
import Circular from "./pages/pages/circular-management/Circular";
import Join from "./pages/pages/discussion/Join";
import UnansweredQuestions from "./pages/pages/discussion/UnansweredQuestions";
import DiscussionAnswers from "./pages/pages/discussion/DiscussionAnswers";
import ForumDiscussion from "./pages/pages/discussion/ForumDiscussion";
import SalesManagement from "./pages/pages/sales-management/SalesManagement";
import PreviewTemplate from "./pages/pages/manage-quiz/PreviewTemplate";
import Students from "./pages/pages/manage-students/Students";
import Subscription from "./pages/pages/manage-subscription/Subscription";
import Invoice from "./pages/pages/Invoice";
import Review from "./pages/pages/manage-reviews/Review";
import StudentReview from "./pages/pages/manage-reviews/StudentReview";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout children={<Default />} />
      </AuthGuard>
    ),
  },
  {
    children: [
      {
        element: (
          <AuthGuard>
            <DashboardLayout children={<Blank />} />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    children: [
      {
        path: CATEGORY_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Category />} />
          </AuthGuard>
        ),
      },
      {
        path: SUB_CATEGORY_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<SubCategory />} />
          </AuthGuard>
        ),
      },
      {
        path: INSTRUCTOR_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Instructor />} />
          </AuthGuard>
        ),
      },
      {
        path: COURSE_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Course />} />
          </AuthGuard>
        ),
      },
      {
        path: COUPON_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Coupon />} />
          </AuthGuard>
        ),
      },
      {
        path: COUPON_USAGE_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<CouponUsage />} />
          </AuthGuard>
        ),
      },
      {
        path: REVIEW_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Review />} />
          </AuthGuard>
        ),
      },
      {
        path: STUDENTS_REVIEW,
        element: (
          <AuthGuard>
            <DashboardLayout children={<StudentReview />} />
          </AuthGuard>
        ),
      },
      {
        path: NOTICE_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Notice />} />
          </AuthGuard>
        ),
      },
      {
        path: ENROLMENT_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Enrolment />} />
          </AuthGuard>
        ),
      },
      {
        path: REGULAR_ENROLMENT_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<RegularEnrolment />} />
          </AuthGuard>
        ),
      },
      {
        path: PROFILE_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Profile />} />
          </AuthGuard>
        ),
      },
      {
        path: COURSE_BUILDER_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<CourseBuilder />} />
          </AuthGuard>
        ),
      },
      {
        path: TEMPLATE_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<QuestionTemplate />} />
          </AuthGuard>
        ),
      },
      {
        path: SECTION_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<SectionList />} />
          </AuthGuard>
        ),
      },
      {
        path: QUESTION_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<QuestionList />} />
          </AuthGuard>
        ),
      },
      {
        path: WRITTEN_EVALUATION_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<WrittenEvaluation />} />
          </AuthGuard>
        ),
      },
      {
        path: CHARACTERISTIC_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Characteristic />} />
          </AuthGuard>
        ),
      },
      {
        path: SITE_INFO_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<SiteInfo />} />
          </AuthGuard>
        ),
      },
      {
        path: BLOG_CATEGORY,
        element: (
          <AuthGuard>
            <DashboardLayout children={<BlogCategory />} />
          </AuthGuard>
        ),
      },
      {
        path: BLOG,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Blog />} />
          </AuthGuard>
        ),
      },
      {
        path: USER_MANAGEMENT,
        element: (
          <AuthGuard>
            <DashboardLayout children={<UserManagement />} />
          </AuthGuard>
        ),
      },
      {
        path: FREE_EXAM,
        element: (
          <AuthGuard>
            <DashboardLayout children={<FreeExam />} />
          </AuthGuard>
        ),
      },
      {
        path: UNANSWERED_QUESTIONS,
        element: (
          <AuthGuard>
            <DashboardLayout children={<UnansweredQuestions />} />
          </AuthGuard>
        ),
      },
      {
        path: JOIN_DISCUSSIONS,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Join />} />
          </AuthGuard>
        ),
      },
      {
        path: QUESTION_DETAILS,
        element: (
          <AuthGuard>
            <DashboardLayout children={<DiscussionAnswers />} />
          </AuthGuard>
        ),
      },
      {
        path: DISCUSSION_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<ForumDiscussion />} />
          </AuthGuard>
        ),
      },
      {
        path: FREE_EXAM_PARTICIPANTS,
        element: (
          <AuthGuard>
            <DashboardLayout children={<FreeExamParticipants />} />
          </AuthGuard>
        ),
      },
      {
        path: CHANGE_PASS,
        element: (
          <AuthGuard>
            <DashboardLayout children={<ChangePasswordPage />} />
          </AuthGuard>
        ),
      },
      {
        path: CIRCULAR_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Circular />} />
          </AuthGuard>
        ),
      },
      {
        path: MANAGE_SALES,
        element: (
          <AuthGuard>
            <DashboardLayout children={<SalesManagement />} />
          </AuthGuard>
        ),
      },
      {
        path: VIEW_TEMPLATE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<PreviewTemplate />} />
          </AuthGuard>
        ),
      },
      {
        path: STUDENTS_PAGE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Students />} />
          </AuthGuard>
        ),
      },
      {
        path: SUBSCRIPTION,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Subscription />} />
          </AuthGuard>
        ),
      },
      {
        path: VIEW_INVOICE,
        element: (
          <AuthGuard>
            <DashboardLayout children={<Invoice />} />
          </AuthGuard>
        ),
      },
      {
        path: RESET_PASSWORD,
        element: <AuthLayout children={<ResetPasswordPage />} />,
      },
    ],
  },
  {
    path: SIGN_IN,
    element: <AuthLayout children={<SignIn />} />,
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
