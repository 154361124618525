import inputTypes from "constants/form/inputTypes";
import { getCategoryForDropdown } from "../../../services/api/queries/category";
import { getInstructorForDropdown } from "../../../services/api/queries/instructor";
import { getSubCategoryForDropdown } from "../../../services/api/queries/subcategory";

const courseFields = [
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "category",
    labelText: "Category",
    placeholder: "Category Name",
    lookupQuery: getCategoryForDropdown,
    rules: { required: true },
  },
  {
    className: "col-5",
    inputType: inputTypes.SELECT,
    key: "sub_category",
    labelText: "Sub-category",
    placeholder: "Sub-category Name",
    lookupQuery: getSubCategoryForDropdown,
    dependencies: ["category"],
    onDependencyValueChange: (
      { category },
      key,
      { performLookupQuery, setValue }
    ) => {
      // setValue("sub_category", null);
      performLookupQuery?.({ category: category });
    },
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Course Name",
    placeHolder: "Enter Course Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name_bn",
    labelText: "Course Name (Bangla)",
    placeHolder: "Enter Course Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "short_description",
    labelText: "Course Description",
    placeHolder: "Enter Course Description",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXTAREA,
    key: "short_description_bn",
    labelText: "Course Description (Bangla)",
    placeHolder: "Enter Course Description",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "overview",
    labelText: "Course Overview",
    placeHolder: "Enter Course Overview",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "overview_bn",
    labelText: "Course Overview (Bangla)",
    placeHolder: "Enter Course Overview",
    rules: { required: true },
  },
  {
    className: "col-5 mb-3",
    inputType: inputTypes.SELECT,
    key: "instructors",
    labelText: "Course Instructor",
    placeholder: "Select Course Instructor",
    lookupQuery: getInstructorForDropdown,
    isMulti: true,
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "intro_video_url",
    labelText: "Course Inrto URL",
    placeHolder: "Enter Course Intro URL",
    rules: { required: true },
  },

  {
    className: "col-4",
    inputType: inputTypes.SELECT,
    key: "c_type",
    labelText: "Course Type",
    isMulti: true,
    placeholder: "Select Course Type",
    options: [
      {
        label: "Live Courses",
        value: "live_course",
      },
      {
        label: "Pre-recorded",
        value: "pre_recorded",
      },
      {
        label: "Lifetime Access",
        value: "life_time_access",
      },
    ],
  },

  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "fee_type",
    labelText: "Course Fee",
    rules: { required: true },
    options: [
      {
        label: "Paid",
        value: "paid",
      },
      {
        label: "Free",
        value: "free",
      },
    ],
  },

  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "regular_price",
    labelText: "Regular Price",
    placeHolder: "Enter Price",
    rules: { required: true },
    hide: true,
    dependencies: ["fee_type"],
    onDependencyValueChange: ({ fee_type }, key, { toggleVisibility }) => {
      toggleVisibility("regular_price", fee_type !== "paid");
    },
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "discount_value",
    labelText: "Discount Value",
    placeHolder: "Enter Amount",
    rules: { required: true },
    hide: true,
    dependencies: ["fee_type"],
    onDependencyValueChange: ({ fee_type }, key, { toggleVisibility }) => {
      toggleVisibility("discount_value", fee_type !== "paid");
    },
  },
  {
    className: "col-4",
    inputType: inputTypes.SELECT,
    key: "discount_type",
    labelText: "Discount Type",
    placeholder: "Discount Type",
    show: false,
    options: [
      {
        label: "Percentage",
        value: "percentage",
      },
      {
        label: "Flat",
        value: "flat",
      },
    ],
    rules: { required: true },
    // hide: true,
    dependencies: ["fee_type"],
    onDependencyValueChange: (
      { fee_type },
      key,
      { toggleVisibilityOfSelect }
    ) => {
      toggleVisibilityOfSelect(fee_type !== "paid");
    },
  },

  {
    className: "mt-2",
    inputType: inputTypes.CUSTOM,
    render: () => <h6>Course Duration (Optional)</h6>,
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "total_topic",
    placeHolder: "Enter Section/Topics Number",
    rules: { required: true },
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.NUMBER,
    key: "total_lecture",
    placeHolder: "Enter Lecture Number",
    rules: { required: true },
  },
  {
    className: "mb-3 col-4 d-inline-block",
    inputType: inputTypes.TEXT,
    key: "duration",
    placeHolder: "Enter Time Duration",
    rules: { required: true },
  },
  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "accessibility",
    labelText: "Course Accessibility",
    rules: { required: true },
    options: [
      {
        label: "Subscription",
        value: "subscription",
      },
      {
        label: "Lifetime Access",
        value: "lifetime",
      },
    ],
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.DATE,
    key: "end_date",
    placeHolder: "Select End Date",
    labelText: "End Date",
    hide: true,
    dependencies: ["accessibility"],
    onDependencyValueChange: ({ accessibility }, key, { toggleVisibility }) => {
      toggleVisibility?.("end_date", accessibility !== "subscription");
    },
  },
  {
    className: " mb-3 col-12",
    inputType: inputTypes.SELECT,
    key: "difficulty",
    labelText: "Difficulty Level",
    placeholder: "Difficulty Level",
    options: [
      {
        label: "Beginner",
        value: "beginner",
      },
      {
        label: "Intermediate",
        value: "intermediate",
      },
      {
        label: "Advanced",
        value: "advanced",
      },
    ],
    rules: { required: true },
  },
  {
    inputType: inputTypes.CHECKBOX,
    key: "is_best_seller",
    labelText: "Best Seller",
    className: "mb-1",
  },
  {
    inputType: inputTypes.LIST_INPUT,
    key: "amenities",
    labelText: "Amenities",
    className: "mb-1",
    fields: [
      {
        inputType: inputTypes.TEXT,
        className: "col-12",
        labelText: "Name",
        key: "name",
        placeHolder: "Name",
      },
    ],
  },
  {
    inputType: inputTypes.LIST_INPUT,
    key: "amenities_bn",
    labelText: "Amenities (Bangla)",
    className: "mb-1",
    fields: [
      {
        inputType: inputTypes.TEXT,
        className: "col-12",
        labelText: "Name",
        key: "name",
        placeHolder: "Name",
      },
    ],
  },
  {
    className: "mb-3 col-12",
    inputType: inputTypes.NUMBER,
    key: "additional_enrollment",
    labelText: "Additional Enrolment Count",
    placeHolder: "Enter Student Amount",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "cover_photo",
    labelText: "Cover Photo:",
    multiple: false,
  },
  {
    className: "col-12 mb-3",
    inputType: inputTypes.SELECT,
    key: "status",
    labelText: "Course Status",
    placeholder: "Course Status",
    options: [
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Published",
        value: "published",
      },
      {
        label: "Draft",
        value: "draft",
      },
      {
        label: "Inactive",
        value: "inactive",
      },
      {
        label: "Enroll Disabled",
        value: "enroll_disabled",
      },
    ],
    rules: { required: true },
  },
];

export default courseFields;
