import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createBlog = async (data) => {
  const payload = {
    ...data,
    image: data.image[0],
  };
  return await adminApi({
    endpoint: endpoints.BLOG,
    method: "post",
    data: payload,
  });
};

export const updateBlog = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.BLOG}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getBlog = async (data) => {
  return await adminApi({
    endpoint: endpoints.BLOG,
    method: "get",
    params: data,
  });
};

export const deleteBlog = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.BLOG}${data.id}/`,
    method: "delete",
  });
};

export const getBlogForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: endpoints.BLOG,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((category) => ({
    value: category.id,
    label: category.name,
  }));
};
