import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createEnrolment = async (data) => {
  const splittedContact = data.contact_number?.split("__");
  const payload = {
    ...data,
    ...(splittedContact?.length > 1
      ? {
          user: splittedContact?.[0],
          contact_number: splittedContact?.[1],
        }
      : { contact_number: splittedContact?.[0] }),
    status: "accepted",
  };
  return await adminApi({
    endpoint: endpoints.ENROLMENT,
    method: "post",
    data: payload,
  });
};

export const getEnrolment = async (data) => {
  const params = {
    ...data,
    e_type: "manual",
  };
  return await adminApi({
    endpoint: endpoints.ENROLMENT,
    method: "get",
    params: params,
  });
};

export const getSalesData = async (data) => {
  const params = {
    ...data,
  };
  return await adminApi({
    endpoint: endpoints.ENROLMENT,
    method: "get",
    params: params,
  });
};

export const updateEnrolment = async (data) => {
  const payload = { status: data.status };
  return await adminApi({
    endpoint: `${endpoints.ENROLMENT}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const getRegularEnrolment = async (data) => {
  const params = {
    ...data,
    e_type: "regular",
  };
  return await adminApi({
    endpoint: endpoints.ENROLMENT,
    method: "get",
    params: params,
  });
};
export const getSingleEnrolment = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.ENROLMENT}${data.invoice}/`,
    method: "get",
  });
};

export const getStudentsForDrpodown = async (data) => {
  const res = await adminApi({
    endpoint: `${endpoints.USERS}?u_type=student`,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((student) => ({
    value: `${student.id}__${student.username}__${student.first_name}`,
    label: `${student.first_name}  ${student.last_name}  ${student.username}`,
  }));
};

export const getStudentPhoneNumberForDropdown = async (data) => {
  const res = await adminApi({
    endpoint: `${endpoints.USERS}?u_type=student`,
    method: "get",
    params: { page_size: 0 },
  });

  return res.data?.map((student) => ({
    value: `${student.username}`,
    label: `${student.first_name}  ${student.last_name}  ${student.username}`,
  }));
};
