import React, { useEffect } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import logo from "../../assets/img/logo.png";
import { useLocation } from "react-router-dom";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen, hideFromDisplay, setHideFromDisplay } = useSidebar();
  const router = useLocation();
  const pathName = router.pathname;

  useEffect(() => {
    hideFromDisplay && setHideFromDisplay(false);
  }, [pathName]);

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img width="32px" className="me-2" src={logo} alt="Logo" />{" "}
            <span className="align-middle me-3">Instant Job Hub</span>
          </a>

          <SidebarNav items={items} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
