import endpoints from "../../../constants/endpoints";
import { adminApi } from "../client";

export const createQuestion = async (data) => {
  const payload = {
    name: data.name,
    q_type: data.q_type,
    mark: data.mark,
    order_in_topic: 1000,
    has_negative_mark: data.has_negative_mark,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
    answer_explanation: data.answer_explanation,
    video_explanation: data.video_explanation,
    template: data.template_id,
    section: data.section_id,
    options: data.options?.map((option, idx) => ({
      name: option.name,
      correct: option.correct,
      ordering: idx + 1,
    })),
  };
  return await adminApi({
    endpoint: endpoints.QUESTION,
    method: "post",
    data: payload,
  });
};

export const updateQuestion = async (data) => {
  const payload = {
    ...data,
    image: Array.isArray(data.image) ? data.image[0] : data.image,
  };
  return await adminApi({
    endpoint: `${endpoints.QUESTION}${data.id}/`,
    method: "patch",
    data: payload,
  });
};

export const updateOption = async (data) => {
  const payload = {
    answer: data.options
      ?.filter((option) => option.correct)
      ?.map((option) => option.key),
    options: data.options,
  };
  return await adminApi({
    endpoint: `${endpoints.UPDATE_OPTIONS}${data.id}/`,
    method: "post",
    data: payload,
  });
};

export const getQuestion = async (data) => {
  return await adminApi({
    endpoint: endpoints.QUESTION,
    method: "get",
    params: data,
  });
};

export const deleteQuestion = async (data) => {
  return await adminApi({
    endpoint: `${endpoints.QUESTION}${data.id}/`,
    method: "delete",
  });
};
