import inputTypes from "constants/form/inputTypes";
import { getBlogCategoryForDropdown } from "../../../services/api/queries/blogCategory";

const blogFields = [
  {
    className: "col-5 mb-3",
    inputType: inputTypes.SELECT,
    key: "category",
    labelText: "Category",
    placeholder: "Category Name",
    lookupQuery: getBlogCategoryForDropdown,
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title",
    labelText: "Blog Name",
    placeHolder: "Enter Blog Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "title_bn",
    labelText: "Blog Name (Bangla)",
    placeHolder: "Enter Blog Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "description",
    labelText: "Blog Description",
    placeHolder: "Enter Blog Description",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "description_bn",
    labelText: "Blog Description (Bangla)",
    placeHolder: "Enter Blog Description",
    rules: { required: true },
  },
  {
    className: "pb-3",
    inputType: inputTypes.FILE,
    key: "image",
    labelText: "Blog Image:",
    multiple: false,
  },
  {
    className: "mb-3",
    inputType: inputTypes.CHECKBOX,
    key: "show_in_landing",
    labelText: "Show in Landing Page",
  },
];

export default blogFields;
