import inputTypes from "constants/form/inputTypes";
import { getInstructorForDropdown } from "../../../services/api/queries/instructor";

const lectureFields = [
  {
    className: "mb-3",
    inputType: inputTypes.TEXT,
    key: "name",
    labelText: "Lecture Name",
    placeHolder: "Enter Lecture Name",
    rules: { required: true },
  },
  {
    className: "mb-3",
    inputType: inputTypes.RICH_TEXT,
    key: "overview",
    labelText: "Lecture Overview/Description",
    placeHolder: "Enter Lecture Overview/Description",
    rules: { required: false },
  },
  {
    className: "col-5 mb-3",
    inputType: inputTypes.SELECT,
    key: "instructors",
    labelText: "Lecture Instructor",
    placeholder: "Select Lecture Instructor",
    lookupQuery: getInstructorForDropdown,
    isMulti: true,
  },
  {
    className: "mb-3",
    childrenClassName: "d-flex",
    inputType: inputTypes.RADIO,
    key: "l_type",
    labelText: "Lecture Type",
    rules: { required: true },
    options: [
      {
        label: "Pre-recorded",
        value: "pre_recorded",
      },
      {
        label: "Live Classs",
        value: "live_class",
      },
    ],
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.TEXT,
    key: "video_url",
    placeHolder: "Give Vimeo Url ",
    labelText: "Vimeo Url",
    rules: { required: true },
    hide: true,
    dependencies: ["l_type"],
    onDependencyValueChange: ({ l_type }, key, { toggleVisibility }) => {
      toggleVisibility("video_url", l_type !== "pre_recorded");
    },
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.TEXT,
    key: "joining_link",
    placeHolder: "Give Class Url",
    labelText: "Class Url",
    hide: true,
    rules: { required: true },
    dependencies: ["l_type"],
    onDependencyValueChange: ({ l_type }, key, { toggleVisibility }) => {
      toggleVisibility("joining_link", l_type !== "live_class");
    },
  },
  {
    className: "mb-3 col-4",
    inputType: inputTypes.DATE_TIME,
    key: "joining_date",
    placeHolder: "Give Class Joining Date",
    labelText: "Class Joining Date",
    rules: { required: true },
    hide: true,
    dependencies: ["l_type"],
    onDependencyValueChange: ({ l_type }, key, { toggleVisibility }) => {
      toggleVisibility("joining_date", l_type !== "live_class");
    },
  },
  {
    className: "col-10 pb-3",
    inputType: inputTypes.FILE,
    key: "exercise_files",
    labelText: "Upload Exercise Files to this Lecture: ",
    multiple: false,
  },
  {
    className: "col-10 pb-3",
    inputType: inputTypes.FILE,
    key: "class_notes",
    labelText: "Upload Classnotes to this Lecture: ",
    multiple: false,
  },
  {
    inputType: inputTypes.CHECKBOX,
    key: "is_preview",
    labelText: "Enable Free Preview of this Lecture",
    className: "mb-1",
  },
];

export default lectureFields;
