import ConfirmActionButton from "../../../components/actions/ConfirmActionButton";
import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  createBlog,
  deleteBlog,
  getBlog,
  updateBlog,
} from "../../../services/api/queries/blog";
import blogFields from "../formFields/blogFields";

const blogListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Sort",
        accessor: "sort",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Title (Bangla)",
        accessor: "title_bn",
      },
      {
        Header: "Category",
        accessor: "category.name",
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateBlog}
              fields={blogFields}
              heading={"Edit a Blog!"}
              openerText="Edit"
              parentId="modal_span"
              defaultValues={{
                ...defaultValues,
                category: defaultValues.category.id,
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Blog updated!"
              openerClassName="w-100"
            />
          ),
          (defaultValues) => (
            <ConfirmActionButton
              openerText="Delete"
              openerClassName="w-100"
              confirmButtonName="Yes"
              refreshEvent="refresh_table"
              queryService={deleteBlog}
              value={defaultValues}
              confirmationMessage="Do you really wanna delete this Blog?"
              heading="Delete Blog"
            />
          ),
        ],
      },
    ],
    actions: [
      <ModalFormOpener
        key="edit"
        submitService={createBlog}
        fields={blogFields}
        heading={"Create a Blog!"}
        openerText="+ Add Blog"
        parentId="modal_span"
        refreshEvent="refresh_table"
        size="xl"
        doNotReset={true}
        successMessage="Blog Created!"
        openerClassName="btn btn-primary"
      />,
    ],
    queryService: getBlog,
    modelName: "blog",
    addButtonText: "+ Add Blog",
  };
};

export default blogListViewMeta;
