import ModalFormOpener from "../../../components/actions/ModalFormOpener";
import {
  getReviewsByStudents,
  updateReviews,
} from "../../../services/api/queries/course";
import studentReviewFields from "../formFields/studentReviewFields";

const studentReviewListViewMeta = () => {
  return {
    columns: [
      {
        Header: "Reviewer",
        accessor: "reviewer_name",
      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Course",
        accessor: "course",
        Cell: ({ value }) => value?.name || "",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Show In B2C",
        accessor: "show_in_landing",
        Cell: ({ value }) => (value ? "✓" : "X"),
      },
      {
        Header: "",
        accessor: "actions",
        options: [
          (defaultValues) => (
            <ModalFormOpener
              key="edit"
              submitService={updateReviews}
              fields={studentReviewFields}
              heading={"Update Review Status!"}
              openerText="Accept/Reject"
              parentId="modal_span"
              defaultValues={{
                status: defaultValues.status,
                review: defaultValues.review,
                review_bn: defaultValues.review_bn,
                rating: defaultValues.rating,
                id: defaultValues.id,
                show_in_landing: defaultValues.show_in_landing,
              }}
              refreshEvent="refresh_table"
              size="xl"
              doNotReset={true}
              successMessage="Review updated!"
              openerClassName="w-100"
            />
          ),
        ],
      },
    ],
    actions: [],
    queryService: getReviewsByStudents,
    modelName: "coursereview",
  };
};

export default studentReviewListViewMeta;
